import React from 'react'
import Layout from '../components/Layout'
import Helmet from 'react-helmet'

const Procedures = () => ( 
    <Layout>
        <Helmet>
            <title>Procedures | Dr. Piera Taylor</title>
            <meta name='keywords' content='surgeon, ENT, paediatric, Sydney, doctor, Piera Taylor, 
            cleft palate, hearing issues, salivary management, grommets'/>
            <meta name='description' content="Dr. Piera Taylor, one of Sydney's leading Paediatric ENT Surgeons, specialises in salivary management, cleft palate and ENT conditions for children with diabilities and syndromes."/>
            <meta name='robots' content='index,follow'/>
        </Helmet>
        <section className='hero is-primary is-small'>
            <div className='hero-body'>
                <div className='container'>
                    <div className='columns'>
                        <div className='column is-10 is-offset-1'>
                            <div className='section is-centered'>
                                <h1 className='title'>
                                    What We Do
                                </h1>
                                <h2 className='subtitle is-italic'>
                                    Procedures and Specialties
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='section padding-vertical-small'>
            <div className = 'container padding-vertical-small'>
                <div className = 'section padding-vertical-small'>
                    <div className = 'columns'>
                        <div className = 'column is-5 is-offset-1'>
                            <div className ='content'>
                                <h2 className='is-bold'>Procedures</h2>    
                                <p>Paediatric Tonsillectomy</p>
                                <p>Adenoidectomy</p>
                                <p>Grommets</p>
                                <p>Hearing Issues</p>
                                <p>Myringplasty and Tympanoplasty for Ear drum perforation</p>
                                <p>Cholesteatoma surgery</p>
                                <p>Bone Anchored Hearing aids</p>
                                <p>Surgery for Paediatric Nasal Obstruction</p>
                                <p>Surgery for the Management of Paediatric Snoring</p>
                                <p>Surgery for the Management of Excessive Salivation</p>
                                <br/>
                                <h2 className='is-bold margin-top-zero'>Specialties</h2>
                                <p>Dr Taylor consults in the multidisciplinary Cleft Palate clinic at the children’s Hospital at Westmead on Thursday afternoons. She has a particular interest in ENT conditions that affect children with cleft palate and other disabilities and syndromes.</p>
                                <p>Dr Taylor has a general ENT clinic but also has a specialist clinic in salivary management involving surgery and Botox for drooling and aspiration at the Children’s hospital at Westmead.</p>
                                <p>Dr Taylor can manage patients in both public and private systems. In her private clinic, she sees Paediatric patients with all conditions and disabilities, and works at the Children’s hospital at Westmead for children that need these specialist services.</p>
                            </div>
                        </div>
                        <div className = 'column is-4'>
                            <div className='container'>
                                <div className='content'>
                                    <p className='has-text-centered'>
                                        <figure className='image is-size-5'>
                                            <img alt='Child with fingers in ears' src='/img/kid3.jpg' />
                                        </figure>
                                    </p>
                                    <br/>
                                    <p className='has-text-centered'>
                                        <figure className='image is-size-3'>
                                            <img alt='Child with finger on nose' src='/img/kid2.jpg' />
                                        </figure>
                                    </p>
                                    <br/>
                                    <p className='has-text-centered'>
                                        <figure className='image is-size-7'>
                                            <img alt='Child with finger in mouth' src='/img/kid1.jpg' />
                                        </figure> 
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
)

export default Procedures